import React, { useRef, useEffect } from 'react';
import { motion, useInView } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet'; // Importing Helmet for SEO
import HeroBanner from '../../components/HeroBanner/HeroBanner';
import PromoBanner from '../../components/PromoBanner/PromoBanner';
import SymptomsAndTreatmentsGrid from '../../components/Services/SymptomsAndTreatmentsScrollable';
import BestServices from '../../components/BestServices/BestServices';
import Reviews from '../../components/Reviews/Reviews';
import { NAVBAR_HEIGHT, SCROLL_OFFSET } from '../../components/Navbar/Navbar';
import './HomePage.scss';

const fadeIn = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 1 } },
};

const slideUp = {
  hidden: { y: 100, opacity: 0 },
  visible: { y: 0, opacity: 1, transition: { duration: 0.8 } },
};

const slideLeft = {
  hidden: { x: -100, opacity: 0 },
  visible: { x: 0, opacity: 1, transition: { duration: 0.8 } },
};

function HomePage({ openForm }) {
  const promoRef = useRef(null);
  const heroRef = useRef(null);
  const servicesRef = useRef(null);
  const bestServicesRef = useRef(null);
  const reviewsRef = useRef(null);

  const location = useLocation();

  const promoInView = useInView(promoRef, { once: true });
  const heroInView = useInView(heroRef, { once: true });
  const bestServicesInView = useInView(bestServicesRef, { once: true });
  const reviewsInView = useInView(reviewsRef, { once: true });

  useEffect(() => {
    if (location.state && location.state.scrollTo === 'services') {
      const servicesSection = document.getElementById('services');
      if (servicesSection) {
        setTimeout(() => {
          const yOffset = -(NAVBAR_HEIGHT + SCROLL_OFFSET);
          const element = servicesSection;
          const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
          
          requestAnimationFrame(() => {
            window.scrollTo({
              top: Math.max(0, y),
              behavior: 'smooth'
            });
          });
        }, 300);
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return (
    <div className="home-page">
      <Helmet>
        <title>The Best Dental Hospital in India | Unidental</title>
        <meta name="description" content="The Best Dental Hospital in India with branches in Hyderabad, Anantapur and Goa. We offer dental services like dental Implants, Dentures & Root Canal Treatments etc." />
        <meta name="keywords" content="Best Dental Hospital in India, Best Dental Services, Dental Care, Dental Solutions, Dental services in Hyderabad, Dental services in Goa, Dental services in Anantapur" />
      </Helmet>

      <motion.div
        ref={promoRef}
        variants={fadeIn}
        initial="hidden"
        animate={promoInView ? 'visible' : 'hidden'}
      >
        <PromoBanner
          text="New Patient Special!"
          subtext="Get a comprehensive exam and cleaning for only $99"
        />
      </motion.div>

      <motion.div
        ref={heroRef}
        variants={slideUp}
        initial="hidden"
        animate={heroInView ? 'visible' : 'hidden'}
      >
        <HeroBanner onOpenForm={openForm} />
      </motion.div>

      <div className="content">
        <motion.section
          id="services"
          ref={servicesRef}
          className="services"
          variants={slideUp}
          initial="hidden"
          animate="visible"
          style={{ 
            scrollMarginTop: `${NAVBAR_HEIGHT + SCROLL_OFFSET}px`,
            position: 'relative'
          }}
        >
          <h2>Our Services</h2>
          <SymptomsAndTreatmentsGrid />
        </motion.section>

        <motion.section
          ref={bestServicesRef}
          className="best-services"
          variants={slideLeft}
          initial="hidden"
          animate={bestServicesInView ? 'visible' : 'hidden'}
        >
          <h2>Why UNIDENTAL</h2>
          <BestServices />
        </motion.section>

        <motion.section
          ref={reviewsRef}
          className="reviews"
          variants={fadeIn}
          initial="hidden"
          animate={reviewsInView ? 'visible' : 'hidden'}
        >
          <h2>What our customers say</h2>
          <Reviews />
        </motion.section>
      </div>
    </div>
  );
}

export default HomePage;