import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { motion } from "framer-motion";
import { serviceData } from "./serviceData";
import "./ServiceDetail.scss";

const ServiceDetail = () => {
  const { serviceId } = useParams();
  const service = serviceData[`/${serviceId}`];

  useEffect(() => {
    if (service) {
      // Set the document title based on the service data
      document.title = service.documentTitle; // This updates the browser tab title
    }
    window.scrollTo(0, 0);
  }, [serviceId, service]); // Include serviceId and service in the dependency array

  if (!service) {
    return (
      <div className="error-container">
        <div className="error-message">Service not found</div>
      </div>
    );
  }

  // Generate structured data for the service
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "MedicalService",
    "name": service.title,
    "description": service.overview,
    "provider": {
      "@type": "Dentist",
      "name": "Unidental",
      "priceRange": "₹₹₹"
    },
    "medicalSpecialty": service.title,
    "availableService": service.procedures.map(proc => ({
      "@type": "MedicalProcedure",
      "name": proc.name,
      "description": proc.description
    }))
  };

  return (
    <>
      <Helmet>
        <title>{service.documentTitle || `${service.title} at Unidental | Best Dental Clinic`}</title>
        <meta name="description" content={service.metaDescription || service.overview.slice(0, 160)} />
        
        {/* Open Graph tags */}
        <meta property="og:title" content={service.documentTitle || `${service.title} at Unidental`} />
        <meta property="og:description" content={service.metaDescription || service.overview.slice(0, 160)} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        
        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={service.documentTitle || `${service.title} at Unidental`} />
        <meta name="twitter:description" content={service.metaDescription || service.overview.slice(0, 160)} />
        
        {/* Canonical URL */}
        <link rel="canonical" href={window.location.href} />
        
        {/* Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>

      <motion.div className="service-container">
        <motion.div className="service-card">
          <motion.div className="service-header">
            <h1 className="service-title">{service.title}</h1>
          </motion.div>

          <div className="service-content">
            <motion.section className="service-section">
              {/* <h2 className="section-title">Overview</h2> */}
              <motion.p className="section-text">
                {service.overview}
              </motion.p>
            </motion.section>

            <motion.section className="service-section">
              <h2 className="section-title">Procedures</h2>
              <div className="procedures-grid">
                {service.procedures.map((procedure, index) => (
                  <motion.div
                    key={index}
                    className="procedure-card"
                  >
                    <h3 className="procedure-title">{procedure.name}</h3>
                    <p className="procedure-description">
                      {procedure.description}
                    </p>
                  </motion.div>
                ))}
              </div>
            </motion.section>

            <motion.section className="service-section">
              <h2 className="section-title">Benefits</h2>
              <ul className="benefits-list">
                {service.benefits.map((benefit, index) => (
                  <motion.li key={index} className="benefit-item">
                    <span className="bullet">•</span>
                    {benefit}
                  </motion.li>
                ))}
              </ul>
            </motion.section>

            <motion.section className="service-section">
              <h2 className="section-title">Frequently Asked Questions</h2>
              <div className="faq-list">
                {service.faqs.map((faq, index) => (
                  <motion.div key={index} className="faq-item">
                    <h3 className="faq-question">{faq.question}</h3>
                    <p className="faq-answer">{faq.answer}</p>
                  </motion.div>
                ))}
              </div>
            </motion.section>
          </div>
        </motion.div>
      </motion.div>
    </>
  );
};

export default ServiceDetail;