import React, { useState, useEffect } from 'react';
import './Captcha.scss';

const Captcha = ({ onValidate }) => {
  const [captchaText, setCaptchaText] = useState('');
  const [userInput, setUserInput] = useState('');
  const [isValid, setIsValid] = useState(null);

  const generateCaptcha = () => {
    const characters = '0123456789';
    let result = '';
    for (let i = 0; i < 3; i++) {  // Adjust the length as needed
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    setCaptchaText(result);
    setUserInput('');
    setIsValid(null);
    onValidate(false); // Reset validation state when generating new CAPTCHA
  };

  useEffect(() => {
    generateCaptcha();
  }, []);

  const handleInputChange = (e) => {
    setUserInput(e.target.value.toUpperCase());
  };

  const handleVerify = (e) => {
    e.preventDefault(); // Prevent form submission
    const validationResult = userInput === captchaText;
    setIsValid(validationResult);
    onValidate(validationResult);
  };

  return (
    <div className="captcha-container">
      <div className="captcha-row">
        <div className="captcha-text">{captchaText}</div>
        <input
          type="text"
          value={userInput}
          onChange={handleInputChange}
          placeholder="Enter captcha"
          className="captcha-input"
          maxLength={3}
        />
        <button onClick={handleVerify} className="captcha-submit">Verify</button>
        <button onClick={generateCaptcha} className="captcha-refresh" title="Refresh Captcha">↻</button>
      </div>
      {isValid !== null && (
        <div className={`captcha-message ${isValid ? 'valid' : 'invalid'}`}>
          {isValid ? 'Valid' : 'Invalid'}
        </div>
      )}
    </div>
  );
};

export default Captcha;