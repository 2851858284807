import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import './BestServices.scss'

const ServiceShowcase = () => {
  const [currentService, setCurrentService] = useState(0);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const services = [
    { title: 'Patient-Centered Philosophy', description: 'At UNIDENTAL, our approach revolves around you. We prioritize your individual needs and comfort, ensuring that every visit is a personalized and positive experience.' },
    { title: 'Excellence as Our Standard', description: 'Quality is more than just a commitment---it\'s the foundation of our practice. From advanced treatment techniques to stringent hygiene protocols, we deliver the highest standard of care.' },
    { title: 'Comprehensive Dental Care in One Place', description: 'We offer a wide array of dental services under one roof, from preventive care and routine check-ups to specialized treatments. Trust us to manage all of your dental needs seamlessly.' },
    { title: 'A Comfortable, Stress-Free Experience', description: 'We understand that visiting the dentist can be intimidating, which is why we\'ve created an environment designed to put you at ease. Every aspect of your visit is focused on your comfort and care.' }
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentService((prev) => (prev + 1) % services.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [services.length]);

  return (
    <section className="service-showcase">
      <div className="service-showcase__content">
        <AnimatePresence mode="wait">
          <motion.div
            key={currentService}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.5 }}
            className="service-showcase__service"
          >
            <h3 className="service-showcase__service-title">{services[currentService].title}</h3>
            <p className="service-showcase__description">{services[currentService].description}</p>
          </motion.div>
        </AnimatePresence>
      </div>
      <motion.div
        className="service-showcase__progress-bar"
        initial={{ scaleX: 0 }}
        animate={{ scaleX: 1 }}
        transition={{ duration: 5, ease: "linear", repeat: Infinity }}
      />
    </section>
  );
};

export default ServiceShowcase;