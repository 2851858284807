import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Instagram, Facebook, Youtube, Linkedin } from 'lucide-react';
import './Footer.scss';

const Footer = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleServicesClick = (e) => {
    e.preventDefault();
    if (location.pathname !== '/') {
      navigate('/', { state: { scrollTo: 'services' } });
    } else {
      const servicesSection = document.getElementById('services');
      if (servicesSection) {
        const yOffset = -window.innerHeight / 4;
        const y = servicesSection.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    }
  };

  return (
    <footer className="footer">
      <nav className="footer-nav">
        <Link to="/">Home</Link>
        <a href="#services" onClick={handleServicesClick}>Services</a>
        <Link to="/about">About Us</Link>
        <Link to="/contact">Contact</Link>
      </nav>
      <div className="social-icons">
        <a href="https://www.instagram.com/unidental.india/" target="_blank" rel="noopener noreferrer" className="instagram">
          <Instagram />
        </a>
        <a href="https://www.facebook.com/profile.php?id=61567181171187" target="_blank" rel="noopener noreferrer" className="facebook">
          <Facebook />
        </a>
        <a href="https://www.youtube.com/@UNIDENTALINDIA" target="_blank" rel="noopener noreferrer" className="youtube">
          <Youtube />
        </a>
        <a href="https://www.linkedin.com/" target="_blank" rel="noopener noreferrer" className="linkedin">
          <Linkedin />
        </a>
      </div>
      <div className="copyright">
        © 2003-2024 UNIDENTAL India Private Limited. All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;