import React from 'react';
import './Reviews.scss';

const ReviewCards = () => {
  const reviews = [
    { id: 1, name: "Suresh", text: "Great dental service, very professional!", rating: 5 },
    { id: 2, name: "Shashank", text: "The staff was friendly and helpful.", rating: 4 },
    { id: 3, name: "Arjun", text: "Highly recommend for routine checkups.", rating: 5 },
    { id: 4, name: "Amrutha", text: "Affordable and reliable.", rating: 4 },
    { id: 5, name: "Jasvith", text: "Best dental care I've experienced.", rating: 5 },
  ];

  const renderStars = (rating) => {
    const fullStars = Array(rating).fill('★');
    const emptyStars = Array(5 - rating).fill('☆');
    return [...fullStars, ...emptyStars].map((star, index) => (
      <span key={index} className="star">
        {star}
      </span>
    ));
  };

  return (
    <div className="review-cards-container">
      <div className="review-cards-scroller">
        {reviews.concat(reviews).map((review, index) => (
          <div className="review-card" key={index}>
            <div className="review-stars">{renderStars(review.rating)}</div>
            <p className="review-text">"{review.text}"</p>
            <p className="review-author">- {review.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReviewCards;
