import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './Navbar.scss';
import AppointmentForm from '../AppointmentForm/AppointmentForm';
import AppointmentFormManager from '../AppointmentFormManager/AppointmentFormManager';
import logo from "../../assets/images/logo.png";
export const NAVBAR_HEIGHT = 80;
export const SCROLL_OFFSET = 45;

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { openForm, closeForm, isFormOpen } = AppointmentFormManager();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleOpenForm = () => {
    setIsMenuOpen(false);
    setTimeout(() => {
      openForm();
    }, 300);
  };

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);

  const scrollToServices = () => {
    const servicesSection = document.getElementById('services');
    if (servicesSection) {
      const yOffset = -(NAVBAR_HEIGHT + SCROLL_OFFSET);
      const element = servicesSection;
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      
      requestAnimationFrame(() => {
        window.scrollTo({
          top: Math.max(0, y),
          behavior: 'smooth'
        });
      });
    }
  };

  const handleServicesClick = (e) => {
    e.preventDefault();
    setIsMenuOpen(false);
    
    if (location.pathname !== '/') {
      navigate('/', { state: { scrollTo: 'services' } });
    } else {
      scrollToServices();
    }
  };

  return (
    <nav className={`navbar ${isMenuOpen ? 'menu-open' : ''}`}>
      <div className="navbar-content">
        <button className="hamburger" onClick={toggleMenu} aria-label="Toggle menu">
          <span></span>
          <span></span>
          <span></span>
        </button>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Link to="/" style={{ display: "flex", alignItems: "center", textDecoration: "none" }}>
            <img
              src={logo}
              alt="Unidental Logo"
              style={{
                height: "35px",
                width: "35px",
                borderRadius: "50%",
                backgroundColor: "#0b5a4e",
                objectFit: "cover",
                marginRight: "8px",
              }}
            />
            <span
              style={{
                color: "#0b5a4e",
                fontSize: "1.7rem",
                fontWeight: "bold",
              }}
            >
              UNIDENTAL
            </span>
          </Link>
        </div>
        <div className={`nav-links ${isMenuOpen ? 'open' : ''}`}>
          <button 
            className={`close-button ${isMenuOpen ? 'visible' : ''}`} 
            onClick={toggleMenu}
            aria-label="Close menu"
          />
          <ul>
            <li><Link to="/" onClick={() => setIsMenuOpen(false)}>HOME</Link></li>
            <li><a href="#services" onClick={handleServicesClick}>SERVICES</a></li>
            <li><Link to="/about" onClick={() => setIsMenuOpen(false)}>ABOUT US</Link></li>
            <li><Link to="/contact" onClick={() => setIsMenuOpen(false)}>CONTACT</Link></li>
          </ul>
          <button className="appointment-btn" onClick={handleOpenForm}>Book Appointment</button>
        </div>
      </div>
      {isFormOpen && <AppointmentForm closeForm={closeForm} />}
    </nav>
  );
}

export default Navbar;