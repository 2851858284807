import { React, useEffect } from 'react';
import './AboutUs.scss';

const AboutUs = () => {

  useEffect(() => {
    window.scrollTo(0, 0);  // Scrolls to the top when the component is mounted
  }, []);

  document.title = "About Unidental | Committed to Exceptional Dental Care";  // Page Title from SEO sheet

  return (
    <div className="about-us-container">
      <meta name="description" content="Learn about Unidental's mission, values, and dedication to providing exceptional dental care services. Discover our commitment to your oral health and well-being." /> {/* Meta Description from SEO sheet */}

      <p>
        Welcome to Unidental, where exceptional dental care meets compassion and expertise. As one of the best dental care providers in the region, we are dedicated to transforming smiles and enhancing lives. Our practice is built on a commitment to quality, comfort, and comprehensive oral health solutions. At Unidental, we understand that a healthy smile reflects overall well-being and self-confidence, and we aim to provide the best dental care services to every patient.
      </p>

      <h2>Our Mission</h2>
      <p>
        Our mission is clear: to be a trusted partner in your oral health journey. Unidental strives to deliver high-quality, affordable, and compassionate dental care that extends beyond immediate concerns to encompass long-term dental health and prevention. We believe in creating lasting relationships with our patients, founded on trust, transparency, and a dedication to excellence. Each visit is an opportunity to reinforce our mission of fostering healthy, confident smiles for life.
      </p>

      <h2>Our Values</h2>
      <p>
        At Unidental, we uphold values that center around patient satisfaction, ethical care, and continual improvement. We believe in providing patient-centered care where each individual's needs and preferences are respected. Integrity guides every decision we make, ensuring that our patients receive honest recommendations and treatments tailored specifically to their dental needs. By embracing the latest advancements in dental technology and continually enhancing our techniques, we maintain a modern approach that reflects our commitment to innovation.
      </p>

      <h2>Our Services</h2>
      <p>
        Unidental offers a full spectrum of dental services, including <strong>general dentistry</strong> for preventive and restorative care, <strong>cosmetic dentistry</strong> for enhancing smiles, and specialized treatments for more complex dental conditions. From routine cleanings to advanced treatments, our team is equipped to handle a wide range of dental needs. Each service is designed with our patients' comfort and long-term health in mind, ensuring that they receive the best care at every visit.
      </p>

      <h2>Meet Our Team</h2>
      <p>
        Behind every smile we treat is a team of dedicated professionals who bring experience, empathy, and skill to our practice. Our team includes skilled general dentists, specialists in cosmetic and restorative dentistry, and a support staff committed to making your visit as comfortable and positive as possible. Each team member is passionate about their role in helping patients achieve optimal oral health, and they work collaboratively to provide a seamless and enjoyable dental experience.
      </p>

      <h2>Why Choose Unidental?</h2>
      <p>
        Choosing Unidental means choosing a practice where your health and well-being come first. We prioritize the use of advanced technology, evidence-based practices, and compassionate care in every treatment. Our friendly, patient-centered environment allows us to focus on what matters most—creating a positive impact on our patients’ lives through excellent dental care. With a deep understanding of the latest techniques in general and cosmetic dentistry, we stand out as a preferred choice for patients seeking reliable, high-quality dental care.
      </p>

      <p>
        Discover the difference at Unidental. Join us on a journey toward a healthier, more confident smile. Whether you’re here for a routine check-up or a transformative treatment, we are honored to be part of your oral health care. Contact us today to experience exceptional care that truly puts you first.
      </p>
    </div>
  );
};

export default AboutUs;
