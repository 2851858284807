import { useState } from 'react';

const AppointmentFormManager = () => {
  const [isFormOpen, setIsFormOpen] = useState(false);

  const openForm = () => setIsFormOpen(true);
  const closeForm = () => setIsFormOpen(false);

  return { openForm, closeForm, isFormOpen };
};

export default AppointmentFormManager;