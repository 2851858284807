// serviceData.js
export const serviceData = {
  '/general-dentistry': {
    title: 'General Dentistry',
    documentTitle: 'Best General Dentistry in India | Unidental | Deep Teeth Cleaning',
    overview: 'General dentistry forms the foundation of good oral health. Our comprehensive general dentistry services focus on prevention, diagnosis, and treatment of various dental conditions to maintain your oral health. We provide services including deep teeth cleaning, dentistry for root canals, and enamel restoration.',
    procedures: [
      {
        name: 'Dental Cleanings',
        description: 'Professional cleaning to remove plaque and tartar, keeping your teeth and gums healthy. Our hygienists use advanced ultrasonic technology and gentle techniques for thorough cleaning and comfort.',
      },
      {
        name: 'Exams and X-rays',
        description: 'Thorough examination and diagnostic imaging to detect oral health issues early, including comprehensive oral cancer screening and periodontal evaluation.',
      },
      {
        name: 'Cavity Fillings',
        description: 'Treatment of tooth decay using modern, tooth-colored composite materials for a natural look and restored function.',
      },
      {
        name: 'Fluoride Treatments',
        description: 'Professional fluoride treatments to strengthen enamel and protect against decay, including deep remineralization for early signs of decay.',
      },
      {
        name: 'Sealants',
        description: 'Protective coating applied to prevent decay in teeth\'s grooves, providing lasting protection for vulnerable areas.',
      }
    ],
    benefits: [
      'Prevention of tooth decay and gum disease',
      'Early detection of dental problems',
      'Maintenance of overall oral health',
      'Protection against future dental issues',
      'Cost-effective preventive care',
    ],
    faqs: [
      {
        question: 'How often should I have a dental check-up?',
        answer: 'We recommend visiting every 6 months for routine check-ups and professional cleaning to maintain oral health.',
      }
    ],
    metaDescription: 'General dentistry treatment at Unidental focuses on diagnosing, treating, and preventing oral diseases with services like deep teeth cleaning and root canal dentistry.',
  },

  '/cosmetic-dental-services': {
    title: 'Cosmetic Dentistry',
    documentTitle: 'Best Cosmetic Dental Services in India | Best Dental Clinic',
    overview: 'Our cosmetic dentistry services enhance your smile\'s appearance with treatments such as veneers, teeth whitening, and teeth capping to give you a confident and natural-looking smile.',
    procedures: [
      {
        name: 'Teeth Whitening',
        description: 'Professional whitening treatments to remove stains and brighten your smile, with both in-office and custom take-home options.',
      },
      {
        name: 'Veneers',
        description: 'Custom-made porcelain shells crafted for a beautiful, natural appearance, ideal for front teeth improvement.',
      }
    ],
    benefits: [
      'Enhanced smile aesthetics',
      'Improved confidence',
      'Natural-looking results',
      'Minimally invasive options available',
    ],
    faqs: [
      {
        question: 'How long do cosmetic dental procedures last?',
        answer: 'The longevity varies by procedure. Whitening may need periodic touch-ups, while veneers can last up to 15 years with proper care.',
      }
    ],
    metaDescription: 'Unidental provides expert cosmetic dentistry services including veneers, teeth capping, and whitening for a stunning smile transformation.',
  },

  '/best-invisalign-braces': {
    title: 'Orthodontics',
    documentTitle: 'Best Invisalign Braces at Unidental | Invisible Braces',
    overview: 'Our orthodontic services cover braces, Invisalign, and orthodontic treatments for all ages, helping you achieve the perfect smile with maximum comfort.',
    procedures: [
      {
        name: 'Traditional Metal Braces',
        description: 'Effective teeth alignment with modern metal braces for complex orthodontic issues.',
      },
      {
        name: 'Clear Aligners',
        description: 'Discreet teeth straightening with custom-made, removable aligners.',
      }
    ],
    benefits: [
      'Improved smile aesthetics',
      'Better bite alignment',
      'Easier cleaning and maintenance',
      'Enhanced oral health',
    ],
    faqs: [
      {
        question: 'How long does orthodontic treatment take?',
        answer: 'Treatment duration varies, typically between 12 and 36 months depending on case complexity.',
      }
    ],
    metaDescription: 'Unidental offers orthodontic services including braces and Invisalign, helping you achieve an aligned smile with comfort and ease.',
  },

  '/best-root-canal-treatments-in-india': {
    title: 'Endodontics',
    documentTitle: 'Root Canal Treatment at Unidental | Cracked Tooth Treatments',
    overview: 'Endodontic treatment focuses on saving natural teeth through procedures like root canal therapy and cracked tooth treatments. Using advanced technology, we provide precise, comfortable care for lasting results.',
    procedures: [
      {
        name: 'Root Canal Therapy',
        description: 'Treatment to remove infected pulp and save the natural tooth. Using microscopic visualization and modern techniques, we thoroughly clean and seal the root canal system for effective treatment.',
      },
      {
        name: 'Retreatment',
        description: 'Revision of previous root canal treatment when necessary, addressing new or persistent infection to save your natural tooth.',
      },
      {
        name: 'Apicoectomy',
        description: 'Surgical procedure to treat infection at the root tip, removing infected tissue and sealing the root end.',
      },
      {
        name: 'Cracked Tooth Treatment',
        description: 'Specialized diagnosis and treatment to prevent further damage and preserve natural tooth structure.',
      },
      {
        name: 'Emergency Care',
        description: 'Immediate treatment for severe tooth pain and dental emergencies requiring root canal therapy.'
      }
    ],
    benefits: [
      'Relief from tooth pain',
      'Preservation of natural teeth',
      'Prevention of tooth loss',
      'Restoration of normal bite function',
      'Long-term tooth survival',
      'Prevention of bone loss',
      'Maintenance of natural smile',
      'Cost-effective versus extraction',
      'Minimal recovery time',
      'High success rate'
    ],
    faqs: [
      {
        question: 'Is root canal treatment painful?',
        answer: 'With modern techniques and anesthesia, root canal treatment is comfortable, providing significant pain relief afterward.',
      },
      {
        question: 'How long does a root canal last?',
        answer: 'With proper care, teeth treated with root canal therapy can last a lifetime. Regular dental check-ups are essential for success.',
      }
    ],
    metaDescription: 'Unidental offers expert root canal therapy and cracked tooth treatments to preserve your natural smile and relieve pain.'
  },

  '/gum-disease-treatments': {
    title: 'Periodontics',
    documentTitle: 'Best Gum Disease Treatments at Unidental | The Best Dental Hospital in India',
    overview: 'Our periodontal services treat gum disease and maintain gum health using advanced techniques and technology for effective and lasting results.',
    procedures: [
      {
        name: 'Scaling and Root Planing',
        description: 'Deep cleaning to remove tartar and smooth root surfaces, promoting healing of gum tissues.',
      },
      {
        name: 'Gum Grafting',
        description: 'Surgical procedure to cover exposed roots and treat gum recession, restoring gum tissue and protecting vulnerable root surfaces.',
      }
    ],
    benefits: [
      'Healthier gums',
      'Prevention of tooth loss',
      'Reduced inflammation',
      'Better oral health',
      'Fresh breath',
      'Decreased risk of systemic health issues',
      'Improved smile aesthetics',
      'Long-term gum stability',
      'Enhanced comfort',
      'Better overall health'
    ],
    faqs: [
      {
        question: 'What are the signs of gum disease?',
        answer: 'Common signs include bleeding gums, bad breath, gum recession, and loose teeth. Early detection is crucial for successful treatment.',
      },
      {
        question: 'Is periodontal disease curable?',
        answer: 'While early stages can be reversed, advanced periodontal disease requires ongoing management to control.',
      }
    ],
    metaDescription: 'Unidental offers comprehensive gum disease treatments like scaling, root planing, and gum grafting to support gum health and prevent tooth loss.'
  },

  '/oral-maxillofacial-surgery': {
    title: 'Oral Surgery',
    documentTitle: 'Oral and Maxillofacial Surgery in India | Unidental | Best Dental Hospital',
    overview: 'Our oral surgery services provide solutions for complex dental needs, from tooth extraction to corrective jaw surgery, helping restore oral health and function.',
    procedures: [
      {
        name: 'Wisdom Tooth Extraction',
        description: 'Removal of impacted or problematic wisdom teeth using advanced surgical techniques for precise extraction and minimal recovery time.',
      },
      {
        name: 'Dental Implants',
        description: 'Surgical placement of titanium implants to create stable foundations for replacement teeth, restoring both function and aesthetics.',
      }
    ],
    benefits: [
      'Relief from pain and discomfort',
      'Improved facial structure and alignment',
      'Enhanced stability for dental implants',
      'Better bite function',
      'Improved speech capability',
      'Long-term oral health solutions',
      'Prevention of future complications',
      'Aesthetic improvements'
    ],
    faqs: [
      {
        question: 'Is oral surgery painful?',
        answer: 'Procedures are performed under appropriate anesthesia, and pain can be managed effectively with medication afterward.',
      },
      {
        question: 'How long does recovery take?',
        answer: 'Recovery time varies depending on the procedure, typically ranging from a few days to a few weeks.',
      }
    ],
    metaDescription: 'Unidental offers oral surgery services like wisdom tooth extraction and dental implants for long-term oral health solutions.'
  },

  '/false-teeth-dentures': {
    title: 'Prosthodontics',
    documentTitle: 'Best False Teeth Dentures at Unidental | Restore Your Smile with Confidence',
    overview: 'Prosthodontic services restore damaged or missing teeth using artificial replacements to improve oral function and aesthetics. Our specialists create beautiful, functional restorations that look and feel natural. We utilize advanced materials and techniques to ensure long-lasting results.',
    procedures: [
      {
        name: 'Dentures',
        description: 'Custom-made removable replacements for missing teeth, designed for optimal fit and appearance. Options include full, partial, and implant-supported dentures.',
      },
      {
        name: 'Dental Crowns',
        description: 'Covers damaged teeth, restoring function and appearance with high-quality materials that match your natural teeth.',
      },
      {
        name: 'Bridges',
        description: 'Fixed prosthetic replacing one or more missing teeth, crafted to restore your smile and bite function.',
      },
      {
        name: 'Implant-Supported Prosthesis',
        description: 'Advanced prosthetic anchored by dental implants for maximum stability and natural function.',
      },
      {
        name: 'Full Mouth Reconstruction',
        description: 'Comprehensive restoration of all teeth to rebuild both function and aesthetics for optimal oral health.',
      }
    ],
    benefits: [
      'Restored oral function',
      'Enhanced appearance',
      'Improved self-confidence',
      'Durable, long-lasting solutions',
      'Better chewing ability',
      'Clearer speech',
      'Preserved facial structure',
      'Comfortable fit',
      'Natural-looking results',
      'Improved quality of life'
    ],
    faqs: [
      {
        question: 'How long do dentures last?',
        answer: 'With proper care, dentures can last 5-10 years before needing replacement. Regular adjustments help maintain proper fit.',
      },
      {
        question: 'Are dental crowns noticeable?',
        answer: 'Our crowns are crafted to match your natural teeth for a seamless look using high-quality materials.',
      }
    ],
    metaDescription: 'Unidental offers advanced denture services including full and implant-supported dentures, dental crowns, and bridges to restore your smile.'
  },

'/best-treatment-for-oral-diseases': {
    title: 'Oral Pathology',
    documentTitle: 'Diagnosis and Treatment for Oral Diseases | Oral Pathology at Unidental',
    overview: 'Our oral pathology services focus on diagnosing and managing diseases affecting the oral regions. Using advanced diagnostic techniques, we identify and treat a range of conditions for successful outcomes.',
    procedures: [
      {
        name: 'Biopsy',
        description: 'Sampling of suspicious oral lesions for diagnostic evaluation, including incisional and brush biopsies.',
      },
      {
        name: 'Oral Cancer Screening',
        description: 'Routine screening using advanced visualization technology to detect oral cancers early for better outcomes.',
      },
      {
        name: 'Lesion Removal',
        description: 'Surgical removal of concerning growths or lesions with precise techniques and minimal tissue disruption.',
      }
    ],
    benefits: [
      'Early detection of oral diseases',
      'Timely treatment planning',
      'Comprehensive oral health monitoring',
      'Prevention of disease progression',
      'Advanced diagnostic capabilities',
    ],
    faqs: [
      {
        question: 'What conditions does oral pathology diagnose?',
        answer: 'Oral pathology diagnoses conditions like oral cancer, infections, and mucosal diseases affecting the oral cavity.',
      },
      {
        question: 'What are common signs of oral pathology issues?',
        answer: 'Unusual lumps, persistent sores, white or red patches, and changes in oral tissue texture may indicate pathology issues.',
      }
    ],
    metaDescription: 'Unidental offers expert diagnosis and treatment of oral diseases through comprehensive oral pathology services for optimal health outcomes.'
  },

'/best-treatment-for-mouth-ulcers': {
    title: 'Oral Medicine',
    documentTitle: 'Mouth Ulcers Treatment | Comprehensive Care for Your Mouth',
    overview: 'Oral medicine addresses complex oral issues often linked to systemic conditions. Our specialists provide comprehensive care for patients, working closely with other medical professionals to ensure coordinated treatment.',
    procedures: [
      {
        name: 'Management of Oral Lesions',
        description: 'Treatment and monitoring of oral sores and ulcers using advanced therapeutic approaches.',
      },
      {
        name: 'Treatment of Oral Infections',
        description: 'Diagnosis and treatment of oral fungal, viral, and bacterial infections with targeted therapies.',
      },
      {
        name: 'Chronic Pain Management',
        description: 'Comprehensive treatment of chronic oral pain, including burning mouth syndrome and neuralgias.',
      }
    ],
    benefits: [
      'Relief from oral pain',
      'Improved management of systemic conditions',
      'Expert medical consultation',
      'Coordinated care approach',
      'Better quality of life',
    ],
    faqs: [
      {
        question: 'What is the role of oral medicine?',
        answer: 'Oral medicine addresses oral health issues related to conditions like autoimmune diseases and infections.',
      },
      {
        question: 'Do oral infections affect general health?',
        answer: 'Yes, untreated oral infections can impact overall health and may complicate medical conditions.',
      }
    ],
    metaDescription: 'Unidental provides specialized oral medicine services for complex oral conditions and infections linked with other health issues.'
  },
  '/dental-implants': {
    title: 'Dental Implants',
    documentTitle: 'Best Dental Implant Clinic | Full Mouth Dental Implants',
    overview: 'Dental implants offer a permanent, natural-looking solution to replace missing teeth, restoring both function and aesthetics. Our advanced implant techniques ensure precise placement and integration, providing long-lasting results that look and feel like real teeth.',
    procedures: [
      {
        name: 'Single Tooth Implant',
        description: 'Replacement of a single missing tooth with a titanium implant and custom-crafted crown, perfectly matched to your natural teeth.',
      },
      {
        name: 'Implant-Supported Bridge',
        description: 'Solution for multiple missing teeth using strategically placed implants to anchor a permanent bridge, restoring full function and appearance.',
      },
      {
        name: 'Full Arch Restoration',
        description: 'Complete replacement of all teeth in the upper or lower jaw with advanced techniques for maximum stability and comfort.',
      },
      {
        name: 'Mini Implants',
        description: 'Smaller diameter implants ideal for patients with limited bone volume or specific placement needs.',
      },
      {
        name: 'Immediate Load Implants',
        description: 'Same-day tooth replacement for qualifying patients, minimizing time spent without teeth and maximizing convenience.',
      }
    ],
    benefits: [
      'Permanent tooth replacement',
      'Enhanced chewing and speaking',
      'Natural look and feel',
      'Durable and long-lasting',
      'Preserves jawbone health',
      'Improved facial aesthetics',
      'No impact on adjacent teeth',
      'Boosted self-confidence',
      'Improved oral health',
      'Lifetime solution'
    ],
    faqs: [
      {
        question: 'Are dental implants painful?',
        answer: 'The procedure is performed with local anesthesia, and most patients report minimal discomfort afterward. We offer pain management options for your comfort.',
      },
      {
        question: 'How long do dental implants last?',
        answer: 'With proper care, dental implants can last a lifetime. The crown may need replacement after 10-15 years due to wear.',
      }
    ],
    metaDescription: 'Unidental offers advanced dental implants for single, multiple, or full arch replacements, restoring smiles with natural-looking, durable solutions.'
  },

'/dental-laser-treatment': {
    title: 'Laser Dentistry',
    documentTitle: 'Best Dental Laser Treatment at Unidental | Best Dental Clinic',
    overview: 'Laser dentistry offers minimally invasive treatment options that enhance comfort and speed recovery. Our advanced laser technology allows for precise, gentle care with less anesthesia and faster healing times.',
    procedures: [
      {
        name: 'Gum Contouring',
        description: 'Laser reshaping of the gums for improved aesthetics or health, with precise tissue management and minimal bleeding.',
      },
      {
        name: 'Cavity Detection and Treatment',
        description: 'Laser technology allows early cavity detection and conservative treatment, preserving healthy tooth structure.',
      },
      {
        name: 'Teeth Whitening',
        description: 'Laser-assisted whitening for fast, dramatic results, activating whitening agents for optimal brightness.',
      }
    ],
    benefits: [
      'Minimally invasive procedures',
      'Reduced pain and discomfort',
      'Faster recovery times',
      'Enhanced precision',
      'Less bleeding during procedures',
      'Better tissue preservation',
      'Improved sterilization'
    ],
    faqs: [
      {
        question: 'Is laser dentistry safe?',
        answer: 'Yes, laser dentistry is safe and precise, with minimal discomfort. Our FDA-approved lasers are operated by trained professionals.',
      },
      {
        question: 'Does laser treatment hurt?',
        answer: 'Laser treatments are typically pain-free and often require little to no anesthesia.',
      }
    ],
    metaDescription: 'Unidental provides advanced laser dental treatments, offering minimally invasive care with faster recovery and superior results.'
  },

'/kids-dentist': {
    title: 'Pediatric Dentistry',
    documentTitle: 'Best Pediatric Dental Services at Unidental | Kids Dentist',
    overview: 'Our pediatric dentistry services address children\'s unique dental needs, focusing on gentle, preventive care to support lifelong oral health. We create a fun, welcoming environment that helps kids develop positive associations with dental care.',
    procedures: [
      {
        name: 'Dental Cleanings for Children',
        description: 'Gentle cleanings to maintain cavity-free teeth, using child-friendly techniques to make the experience enjoyable and educational.',
      },
      {
        name: 'Fluoride Treatments',
        description: 'Strengthens enamel and protects against cavities with professional-grade fluoride, tailored for different age groups.',
      }
    ],
    benefits: [
      'Early detection of dental issues',
      'Preventative care for lifelong oral health',
      'Gentle treatments for kids',
      'Proper oral hygiene habits',
      'Positive dental experiences',
      'Prevention of dental anxiety'
    ],
    faqs: [
      {
        question: 'When should my child first see a dentist?',
        answer: 'The American Dental Association recommends a child\'s first visit by age 1 or within 6 months after the first tooth appears.',
      }
    ],
    metaDescription: 'Unidental offers expert pediatric dentistry with gentle, preventive care that helps children develop healthy dental habits in a comfortable environment.'
  }
};