import React, { useRef } from 'react';
import styled from 'styled-components';
import { motion, useInView } from 'framer-motion';
import { useNavigate } from 'react-router-dom';


const GridContainer = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(4, minmax(250px, 1fr)); // 4 cards per row on larger screens
  gap: 16px;
  padding: 20px; // Padding around the cards
  max-width: 1200px; // Set a maximum width for the container
  margin: 0 auto; // Center the grid container within the parent
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  
  @media (max-width: 1024px) {
    grid-template-columns: repeat(3, minmax(200px, 1fr)); // 3 cards per row for tablets
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, minmax(150px, 1fr)); // 2 cards per row for mobile
    padding: 10px 5%; // Adjust padding for mobile
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr; // 1 card per row for extra small screens
    padding: 10px 5%; // Adjust padding for small screens
  }
`;

const Card = styled(motion.div)`
  background-color: #ffffff;
  border-radius: 12px;
  border: 1px solid #0b5a4e; // Outline for the card
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  padding: 20px;
  height: auto; // Let the height adjust based on content
  min-height: 200px; // Ensure a minimum height

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const CardTitle = styled.h3`
  font-size: 1.5rem;
  color: #0b5a4e;
  margin-bottom: 10px;
  flex-shrink: 0; // Prevent title from shrinking when space is limited
`;

const CardText = styled.div`
  font-size: 1rem;
  color: #333;
  margin: 5px 0;
  line-height: 1.6; // Adjust line height for better readability
  overflow: hidden;
  text-overflow: ellipsis;

  ul {
    list-style-type: disc; // Bullet points style
    padding-left: 20px; // Proper indentation for bullet points
    margin: 0;
  }

  li {
    margin-bottom: 8px; // Even spacing between list items
    font-size: 1rem;
    text-align: left; // Ensure proper alignment
  }

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

// Sample card data with properly formatted descriptions
const cardData = [
  {
    label: 'General Dentistry',
    description: (
      <ul>
        <li>Dental cleanings</li>
        <li>Exams and X-rays</li>
        <li>Cavity fillings</li>
        <li>Fluoride treatments</li>
        <li>Sealants</li>
      </ul>
    ),
    route: 'services/general-dentistry',
  },
  {
    label: 'Cosmetic Dentistry',
    description: (
      <ul>
        <li>Teeth whitening</li>
        <li>Veneers</li>
        <li>Bonding</li>
        <li>Smile makeovers</li>
        <li>Tooth reshaping</li>
      </ul>
    ),
    route: 'services/cosmetic-dental-services',
  },
  {
    label: 'Orthodontics',
    description: (
      <ul>
        <li>Traditional metal braces</li>
        <li>Clear aligners</li>
        <li>Retainers</li>
        <li>Early orthodontic treatment for children</li>
      </ul>
    ),
    route: 'services/best-invisalign-braces',
  },
  {
    label: 'Endodontics',
    description: (
      <ul>
        <li>Root canal therapy</li>
        <li>Retreatment of failed root canals</li>
        <li>Apicoectomy</li>
      </ul>
    ),
    route: 'services/best-root-canal-treatments-in-india',
  },
  {
    label: 'Periodontics',
    description: (
      <ul>
        <li>Scaling and root planing</li>
        <li>Gum grafting</li>
        <li>Treatment of gum disease</li>
        <li>Bone grafting</li>
      </ul>
    ),
    route: 'services/gum-disease-treatments',
  },
  {
    label: 'Oral and Maxillofacial Surgery',
    description: (
      <ul>
        <li>Tooth extractions</li>
        <li>Dental implants</li>
        <li>Bone grafts</li>
        <li>TMJ surgery</li>
        <li>Jaw surgery</li>
      </ul>
    ),
    route: 'services/oral-maxillofacial-surgery',
  },
  {
    label: 'Prosthodontics',
    description: (
      <ul>
        <li>Dental crowns</li>
        <li>Bridges</li>
        <li>Full and partial dentures</li>
        <li>Implant-supported dentures</li>
        <li>Veneers for damaged or discolored teeth</li>
      </ul>
    ),
    route: 'services/false-teeth-dentures',
  },
  {
    label: 'Oral Pathology',
    description: (
      <ul>
        <li>Oral cancer screenings</li>
        <li>Diagnosis and biopsy of oral lesions</li>
        <li>Management of benign or malignant oral diseases</li>
      </ul>
    ),
    route: 'services/best-treatment-for-oral-diseases',
  },
  {
    label: 'Oral Medicine',
    description: (
      <ul>
        <li>TMJ disorder treatment</li>
        <li>Diagnosis of oral lesions</li>
        <li>Management of dry mouth</li>
        <li>Treatment of oral infections</li>
      </ul>
    ),
    route: 'services/best-treatment-for-mouth-ulcers',
  },
  {
    label: 'Dental Implants',
    description: (
      <ul>
        <li>Single tooth implants</li>
        <li>Implant-supported bridges</li>
        <li>Full-arch dental implants</li>
        <li>Mini implants</li>
      </ul>
    ),
    route: 'services/dental-implants',
  },
  {
    label: 'Laser Dentistry',
    description: (
      <ul>
        <li>Laser gum treatments</li>
        <li>Frenectomy</li>
        <li>Cavity detection and treatment</li>
        <li>Teeth whitening</li>
        <li>Soft tissue surgery</li>
      </ul>
    ),
    route: 'services/dental-laser-treatment',
  },
  {
    label: 'Pediatric Dentistry',
    description: (
      <ul>
        <li>First dental visits</li>
        <li>Cleanings and exams</li>
        <li>Fluoride treatments</li>
        <li>Sealants</li>
        <li>Early orthodontic screenings</li>
      </ul>
    ),
    route: 'services/kids-dentist',
  }
];

// Main component to render grid of cards
const SymptomsAndTreatmentsGrid = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const navigate = useNavigate();

  const handleCardClick = (route) => {
    navigate(route);
  };

  const cardAnimation = {
    hidden: { opacity: 0, y: 50 },
    visible: (i) => ({
      opacity: 1,
      y: 0,
      transition: { delay: i * 0.1, duration: 0.5 }, // Staggered animation for each card
    }),
  };

  return (
    <div ref={ref}>
      <GridContainer>
        {cardData.map((item, index) => (
          <Card
            key={index}
            custom={index} // Pass the index to control animation delay
            initial="hidden"
            animate={isInView ? 'visible' : 'hidden'}
            variants={cardAnimation}
            onClick={() => handleCardClick(item.route)}
          >
            <CardTitle>{item.label}</CardTitle>
            <CardText>{item.description}</CardText>
          </Card>
        ))}
      </GridContainer>
    </div>
  );
};

export default SymptomsAndTreatmentsGrid;
