import React, { useState, useEffect } from 'react';
import './HeroBanner.scss';
import image3 from '../../assets/images/dental_img3.jpeg'
import image4 from '../../assets/images/dental_img4.jpeg'
import image5 from '../../assets/images/dental_img5.jpeg'
import image6 from '../../assets/images/dental_img8.jpeg'
import image7 from '../../assets/images/dental_img7.jpeg'
import image9 from '../../assets/images/dental_img9.jpeg'
import image10 from '../../assets/images/dental_img10.jpeg'
import image11 from '../../assets/images/dental_img11.jpeg'
import image12 from '../../assets/images/dental_img12.jpeg'
import { useNavigate } from 'react-router-dom';

const images = [image9, image10, image11, image12, image5, image3, image6, image7];

function HeroBanner({ onOpenForm }) {
  const [currentImage, setCurrentImage] = useState(0);
  const navigate = useNavigate(); 
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(timer);
  }, []);

  const handleLearnMore = () => {
    navigate('/about');  // Navigate to the About Us page
  };

  return (
    <div className="hero-banner">
      <div className="hero-content">
          <h1>Welcome to UNIDENTAL</h1>
          <h2>Your Smile, Our Passion</h2>
          <p>Experience top-notch dental care with our team of experienced professionals.</p>
          <div className="button-container">
              <button className="cta-button" onClick={onOpenForm}>Book an Appointment</button>
              <button className="secondary-button" onClick={handleLearnMore}>Learn More</button>
          </div>
      </div>
      <div className="hero-image">
        {images.map((img, index) => (
          <img
            key={index}
            src={img}
            alt={`Dental care ${index + 1}`}
            className={index === currentImage ? 'active' : ''}
          />
        ))}
      </div>
    </div>
  );
}

export default HeroBanner;